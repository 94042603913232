<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('owwner applicant')">
          <b-row
            v-if="data.owwner"
          >
            <b-col
              v-for="el in ['no','owner', 'applicant','date_time', 'phone', 'identify']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="$t(SelectedOwner(data.owwner)[el])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_numbe') }}</label>
                <b-form-input
                  v-model="data.chassis_number"
                  :formatter="formatter"
                  class="mt-2"
                  :state="errors.chassis_number ?false:null"
                  :placeholder="$t('Chassis_numbe')"
                />
                <small
                  v-if="errors.chassis_number"
                  class="text-danger"
                >{{ errors.chassis_number[0] }}</small>
                <label>{{ $t('Chassis_numbe') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_number_type') }}</label>
                <b-form-input
                  v-model="data.chassis_number_type"
                  class="mt-2"
                  :state="errors.chassis_number_type ?false:null"
                  :placeholder="$t('chassis_number_type')"
                />
                <small
                  v-if="errors.chassis_number_type"
                  class="text-danger"
                >{{ errors.chassis_number_type[0] }}</small>
                <label>{{ $t('chassis_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number"
                  class="mt-2"
                  :state="errors.cabin_reserve_number ?false:null"
                  :placeholder="$t('cabin_reserve_number')"
                />
                <small
                  v-if="errors.cabin_reserve_number"
                  class="text-danger"
                >{{ errors.cabin_reserve_number[0] }}</small>
                <label>{{ $t('cabin_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number_type"
                  class="mt-2"
                  :state="errors.cabin_reserve_number_type ?false:null"
                  :placeholder="$t('cabin_reserve_number_type')"
                />
                <small
                  v-if="errors.cabin_reserve_number_type"
                  class="text-danger"
                >{{ errors.cabin_reserve_number_type[0] }}</small>
                <label>{{ $t('cabin_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Created_year') }}</label>
                <cleave
                  v-model="data.created_year"
                  class="form-control"
                  :class="errors.created_year ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('Created_year')"
                />
                <small
                  v-if="errors.created_year"
                  class="text-danger"
                >{{ errors.created_year[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('first_registeraion') }}</label>
                <cleave
                  v-model="data.first_registeraion"
                  class="form-control"
                  :class="errors.first_registeraion ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('first_registeraion')"
                />
                <small
                  v-if="errors.first_registeraion"
                  class="text-danger"
                >{{ errors.first_registeraion[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number') }}</label>
                <b-form-input
                  v-model="data.cabin_number"
                  class="mt-2"
                  :state="errors.cabin_number ?false:null"
                  :placeholder="$t('cabin_number')"
                />
                <small
                  v-if="errors.cabin_number"
                  class="text-danger"
                >{{ errors.cabin_number[0] }}</small>
                <label>{{ $t('cabin_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_number_type"
                  class="mt-2"
                  :state="errors.cabin_number_type ?false:null"
                  :placeholder="$t('cabin_number_type')"
                />
                <small
                  v-if="errors.cabin_number_type"
                  class="text-danger"
                >{{ errors.cabin_number_type[0] }}</small>
                <label>{{ $t('cabin_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number') }}</label>
                <b-form-input
                  v-model="data.motor_number"
                  class="mt-2"
                  :state="errors.motor_number ?false:null"
                  :placeholder="$t('motor_number')"
                />
                <small
                  v-if="errors.motor_number"
                  class="text-danger"
                >{{ errors.motor_number[0] }}</small>
                <label>{{ $t('motor_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_number_type"
                  class="mt-2"
                  :state="errors.motor_number_type ?false:null"
                  :placeholder="$t('motor_number_type')"
                />
                <small
                  v-if="errors.motor_number_type"
                  class="text-danger"
                >{{ errors.motor_number_type[0] }}</small>
                <label>{{ $t('motor_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number"
                  class="mt-2"
                  :state="errors.motor_reserve_number ?false:null"
                  :placeholder="$t('motor_reserve_number')"
                />
                <small
                  v-if="errors.motor_reserve_number"
                  class="text-danger"
                >{{ errors.motor_reserve_number[0] }}</small>
                <label>{{ $t('motor_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number_type"
                  class="mt-2"
                  :state="errors.motor_reserve_number_type ?false:null"
                  :placeholder="$t('motor_reserve_number_type')"
                />
                <small
                  v-if="errors.motor_reserve_number_type"
                  class="text-danger"
                >{{ errors.motor_reserve_number_type[0] }}</small>
                <label>{{ $t('motor_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_manufacturer') }}</label>
                <b-form-input
                  v-model="data.motor_manufacturer"
                  class="mt-2"
                  :state="errors.motor_manufacturer ?false:null"
                  :placeholder="$t('motor_manufacturer')"
                />
                <small
                  v-if="errors.motor_manufacturer"
                  class="text-danger"
                >{{ errors.motor_manufacturer[0] }}</small>
                <label>{{ $t('motor_manufacturer') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_model') }}</label>
                <b-form-input
                  v-model="data.motor_model"
                  class="mt-2"
                  :state="errors.motor_model ?false:null"
                  :placeholder="$t('motor_model')"
                />
                <small
                  v-if="errors.motor_model"
                  class="text-danger"
                >{{ errors.motor_model[0] }}</small>
                <label>{{ $t('motor_model') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('car_type') }}</label>
                <b-form-select
                  v-model="data.car_type"
                  :options="typeoptions"
                />
                <small
                  v-if="errors.car_type"
                  class="text-danger"
                >{{ errors.car_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.other_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('other_fee')"
                  :state="errors.other_fee ?false:null"
                  :class="errors.other_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.other_fee"
                  class="text-danger"
                >{{ errors.other_fee[0] }}</small>
                <label>{{ $t('other_fee') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('applicantions')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.owwner"
                :options="Owwners"
                :reduce="Owwners => Owwners.id"
                label="no"
              >
                <template #option="{ no,applicant,owner }">
                  <span> {{ no }} - {{ applicant }}-({{ owner }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.owwner"
                class="text-danger"
              >{{ errors.owwner[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Carrs')">
              <label>{{ $t('please select...') }}</label>
              <template #option="{ manufacturer,model,car_type }">
                <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
              </template>
              <v-select
                v-model="data.carr"
                :options="Carrs"
                :reduce="Carrs => Carrs.id"
                label="name"
              >
                <template #option="{ manufacturer,model,car_type }">
                  <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.carr"
                class="text-danger"
              >{{ errors.carr[0] }}</small>
            </b-card>
          </b-col>

          <b-col
            cols="12"
          >
            <b-card :title="$t('Platees')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.platee"
                :options="Platees"
                :reduce="Platees => Platees.id"
                label="number"
              />

              <small
                v-if="errors.platee"
                class="text-danger"
              >{{ errors.platee[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('invoice')">
              {{ $t('first_fee') }} : {{ parseFloat(this.Tolls.first) }}$<br>
              {{ $t('yearly_fee') }} : {{ this.data.yearly_fee }}$<br>
              {{ $t('other_fee') }} : {{ this.data.other_fee }}$<br>
              {{ $t('Total') }} : {{ this.data.yearly_fee }} + {{ this.data.other_fee }} + {{ parseFloat(this.Tolls.first) }} ={{ parseFloat(this.Tolls.first)+ parseFloat(this.data.yearly_fee) + parseFloat(this.data.other_fee) }}$<br>
              {{ $t('Total') }} : {{ Total }}<span>&#8378;</span><br>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData()"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-col
          cols="12"
        >
          <b-card :title="$t('Carrs')">
            <b-row
              v-if="data.carr"
            >
              <b-col
                v-for="el in ['no','manufacturer', 'model','passenger', 'axle', 'cylinder','front_overhang','rear_overhang','axle_hole','length','width','wight', 'motor_power', 'motor_size', 'car_type']"
                :key="el"
                cols="2"
              >
                <b-form-group>
                  {{ $t(el) }}
                  <b-form-input
                    plaintext
                    :value="$t(SelectedCar(data.carr)[el])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-else
              class="text-center"
            >
              البيانات غير متاحة
            </div>
          </b-card>
          <b-card
            id="printMe"
            style="visibility: hidden;"
          >
            <div style="width: 100%!important; height: 100%; background-color: white; position: relative; top: 0;right: 0;left: 0;bottom: 0;">
              <img
                src="@/assets/images/logo/logo.jpg"
                style="width: 80px;
            height: 86px;
position: fixed;top:0px;left: 0px;"
              >
              <h4 style="text-align: right; right: 65%!important;color: black;position: fixed;top:70px; ">
                {{ user.user_name }} : منظم المحضر
              </h4>
              <h4 style=" text-align: right; right: 65%!important; color: black;position: fixed;top:100px; ">: &nbsp;   التوقيع
              </h4>
              <h4 style="text-align: right; right: 65%!important; color: black;position: fixed;top:130px; ">
                {{ currentDateTime() }} :&nbsp; التاريخ
              </h4>
              <h4
                v-if="this.data.carr"
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:70px; "
              >
                {{ this.message }} : رقم لوحة المركبة
              </h4>
              <h4
                v-if="this.data.owwner"
                style=" text-align: right; right: 0%!important; color: black;position: fixed;top:100px; "
              >
                &nbsp;  مقدم الطلب   : {{ SelectedOwner(data.owwner)['applicant'] }}

              </h4>
              <h4
                v-if="this.data.owwner"
                style=" right: 0%!important; color: black;position: fixed;top:130px; "
              >
                &nbsp;  اسم المالك  : {{ SelectedOwner(data.owwner)['owner'] }}
              </h4>

              <h2
                style="position: fixed;
                top: 0px;
                right: 0px;
                text-align: right;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >المؤسسة العامة للنقل<br>مديرية النقل في {{ user.company_data.name }}
              </h2>
              <h1
                style="position: fixed;
                top: 0px;
                right: 40%;
                width: 20%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >محضر فحص<br>
              </h1>
              <h3 style="text-align: right; right: 0%!important;color: black;position: fixed;top:160px; ">
                مواصفات المركبة
                <hr style="margin-top: 0px!important;">
              </h3>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                &nbsp;:   الصانع
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                {{ SelectedCar(data.carr)['manufacturer'] }}
              </h4>
              <h4
                style="text-align: right; right: 25%!important;color: black;position: fixed;top:190px; width: 25%"
              >

                &nbsp; :   الطراز
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 25%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                {{ SelectedCar(data.carr)['model'] }}
              </h4>
              <h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:190px; width: 25%"
              >

                &nbsp;  : النوع

              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                {{ $t(SelectedCar(data.carr)['car_type']) }}
              </h4>
              <h4
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                &nbsp;   : الفئة
              </h4> <h4
                v-if="this.data.owner_type"
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:190px; width: 25%"
              >
                {{ this.data.owner_type ? $t('specific') : $t('public') }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
              >
                &nbsp;:   سنة الصنع
              </h4> <h4
                v-if="this.data.created_year"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
              >
                {{ this.data.created_year }}
              </h4>
              <h4
                style="text-align: right; right: 25%!important;color: black;position: fixed;top:220px; width: 25%"
              >

                &nbsp; :   سنة التسجيل الأولى
              </h4> <h4
                v-if="this.data.first_registeraion"
                style="text-align: center; right: 30%!important;color: black;position: fixed;top:220px; width: 25%"
              >
                {{ this.data.first_registeraion }}
              </h4>
              <h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:220px; width: 25%"
              >

                &nbsp;  : رقم البلاغ
              </h4><h4
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:220px; width: 25%"
              >
                &nbsp;   : تاريخ البلاغ
              </h4>

              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
              >
                &nbsp; :   رقم الهيكل
              </h4> <h3
                v-if="this.data.chassis_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
              >
                {{ this.data.chassis_number }}
              </h3>
              <h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:250px; width: 50%"
              >
                &nbsp; :   سمة رقم الهيكل
              </h4> <h4
                v-if="this.data.chassis_number_type"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:250px; width: 50%"
              >
                {{ this.data.chassis_number_type }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:275px; width: 50%"
              >
                &nbsp; :    رقم الكبين
              </h4> <h4
                v-if="this.data.cabin_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:275px; width: 50%"
              >
                {{ this.data.cabin_number }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:275px; width: 50%"
              >
                &nbsp; :     سمة رقم للكبين
              </h4> <h4
                v-if="this.data.cabin_number_type"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:275px; width: 50%"
              >
                {{ this.data.cabin_number_type }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:300px; width: 50%"
              >
                &nbsp; :    رقم الدق للهيكل
              </h4> <h4
                v-if="this.data.cabin_reserve_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:300px; width: 50%"
              >
                {{ this.data.cabin_reserve_number }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:300px; width: 50%"
              >
                &nbsp; :     سمة رقم الدق للهيكل
              </h4> <h4
                v-if="this.data.cabin_reserve_number_type"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:300px; width: 50%"
              >
                {{ this.data.cabin_reserve_number_type }}
              </h4>

              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:325px; width: 50%"
              >
                &nbsp; :    رقم المحرك
              </h4> <h4
                v-if="this.data.motor_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:325px; width: 50%"
              >
                {{ this.data.motor_number }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:325px; width: 50%"
              >
                &nbsp; :     سمة رقم المحرك
              </h4> <h4
                v-if="this.data.motor_number_type"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:325px; width: 50%"
              >
                {{ this.data.motor_number_type }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:350px; width: 50%"
              >
                &nbsp; :    رقم الدق للمحرك
              </h4> <h4
                v-if="this.data.motor_reserve_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:350px; width: 50%"
              >
                {{ this.data.motor_reserve_number }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:350px; width: 50%"
              >
                &nbsp; :     سمة رقم الدق للمحرك
              </h4> <h4
                v-if="this.data.motor_reserve_number_type"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:350px; width: 50%"
              >
                {{ this.data.motor_reserve_number_type }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:375px; width: 50%"
              >
                &nbsp; :    صانع المحرك
              </h4> <h4
                v-if="this.data.motor_manufacturer"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:375px; width: 50%"
              >
                {{ this.data.motor_manufacturer }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:375px; width: 50%"
              >
                &nbsp; :     عدد الركاب
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:375px; width: 50%"
              >
                {{ SelectedCar(data.carr)['passenger'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:400px; width: 50%"
              >
                &nbsp; :    طراز المحرك
              </h4> <h4
                v-if="this.data.motor_model"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:400px; width: 50%"
              >
                {{ this.data.motor_model }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:400px; width: 50%"
              >
                &nbsp; :     عدد المحاور
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:400px; width: 50%"
              >
                {{ SelectedCar(data.carr)['axle'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:425px; width: 50%"
              >
                &nbsp; :    سعة المحرك
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:425px; width: 50%"
              >
                {{ SelectedCar(data.carr)['motor_size'] }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:425px; width: 50%"
              >
                &nbsp; :     البروز الأمامي
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:425px; width: 50%"
              >
                {{ SelectedCar(data.carr)['front_overhang'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:450px; width: 50%"
              >
                &nbsp; :    عدد الأسطوانات
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:450px; width: 50%"
              >
                {{ SelectedCar(data.carr)['cylinder'] }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:450px; width: 50%"
              >
                &nbsp; :      فتحة المحاور
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:450px; width: 50%"
              >
                {{ SelectedCar(data.carr)['axle_hole'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:475px; width: 50%"
              >
                &nbsp; :   قوة المحرك
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:475px; width: 50%"
              >
                {{ SelectedCar(data.carr)['motor_power'] }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:475px; width: 50%"
              >
                &nbsp; :       البروز الخلفي
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:475px; width: 50%"
              >
                {{ SelectedCar(data.carr)['rear_overhang'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:500px; width: 50%"
              >
                &nbsp; :   نوع الوقود
              </h4> <h4
                v-if="this.data.fuel_type"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:500px; width: 50%"
              >
                {{ $t(this.data.fuel_type) }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:500px; width: 50%"
              >
                &nbsp; :       الطول الاجمالي
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:500px; width: 50%"
              >
                {{ SelectedCar(data.carr)['length'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:525px; width: 50%"
              >
                &nbsp; :   اللون الرئيسي
              </h4> <h4
                v-if="this.data.color"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:525px; width: 50%"
              >
                {{ this.data.color1.split(" ")[0] }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:525px; width: 50%"
              >
                &nbsp; :       العرض الاجمالي
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:525px; width: 50%"
              >
                {{ SelectedCar(data.carr)['width'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:550px; width: 50%"
              >
                &nbsp; :  صفة اللون الرئيسي
              </h4> <h4
                v-if="this.data.color2"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:550px; width: 50%"
              >
                {{ this.data.color2 }}
              </h4><h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:550px; width: 50%"
              >
                &nbsp; :       الوزن الاجمالي
              </h4> <h4
                v-if="this.data.carr"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:550px; width: 50%"
              >
                {{ SelectedCar(data.carr)['wight'] }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:600px; width: 50%"
              >
                &nbsp; :       الحالة الفنية
              </h4>
              <h4
                v-if="this.data.brake && this.data.light&& this.data.combustion&& this.data.wheel"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:600px; width: 50%"
              >
                صالحة للسير
              </h4>
              <h4
                v-else
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:600px; width: 50%"
              >
                غير صالحة للسير
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                &nbsp; :      المكابح
              </h4> <h4
                v-if="this.data.brake"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                {{ this.data.brake ? $t('ok') : $t('bad') }}
              </h4>
              <h4
                style="text-align: right; right: 25%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                &nbsp; :       الأنوار
              </h4> <h4
                v-if="this.data.light"
                style="text-align: center; right: 25%!important;color: black;position: fixed;top:625px; width: 25%"
              >

                {{ this.data.light ? $t('ok') : $t('bad') }}
              </h4>
              <h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                &nbsp; :       الاحتراق
              </h4> <h4
                v-if="this.data.combustion"
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                {{ this.data.combustion ? $t('ok') : $t('bad') }}
              </h4>
              <h4
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                &nbsp; :       الإطارات
              </h4> <h4
                v-if="this.data.wheel"
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:625px; width: 25%"
              >
                {{ this.data.wheel ? $t('ok') : $t('bad') }}
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:650px; width: 50%"
              >
                &nbsp; :       ملاحظات أخرى
              </h4> <h4
                v-if="this.data.notes"
                style="text-align: center; right: 5%!important;color: black;position: fixed;top:650px; width: 95%"
              >
                {{ this.data.notes }}
              </h4>

              <h4
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:710px; width: 25%"
              >
                &nbsp; عضو
              </h4>
              <h4
                style="text-align: center; right: 25%!important;color: black;position: fixed;top:710px; width: 25%"
              >
                &nbsp;عضو
              </h4>
              <h4
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:710px; width: 25%"
              >
                &nbsp; عضو
              </h4>
              <h4
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:710px; width: 25%"
              >
                &nbsp;رئيس اللجنة
              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:790px; width: 25%"
              >
                أسماء المالكين

              </h4>
              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:820px; width: 25%"
              > 1

              </h4>    <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:850px; width: 25%"
              > 2

              </h4>

              <h4
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:880px; width: 50%"
              >
                --------/----/----                بتاريخ

              </h4>
              <h4
                style="text-align: right; right: 50%!important;color: black;position: fixed;top:880px; width: 50%"
              >تم نقش إشارة المراقبة مع الأرقام على كل من

              </h4>

            </div>
            <div style="width: 100%!important; height: 100%; background-color: white; position: absolute; top: 100%;right: 0;left: 0;">

              <h3 style=" text-align: right; right: 75%!important; color: black;position: fixed;top:100%; ">: &nbsp;   التوقيع
              </h3>
              <h3 style="text-align: right; right: 75%!important; color: black;position: fixed;top:102%; ">
                {{ currentDateTime() }} :&nbsp; التاريخ
              </h3>
              <h2
                style="position: fixed;
                top: 100%;
                right: 0px;
                text-align: right;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >المؤسسة العامة للنقل<br>مديرية النقل في {{ user.company_data.name }}
              </h2>
              <h1
                style="position: fixed;
                top: 102%;
                right: 25%;
                width: 50%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >عقد تسجيل مركبة حديثة<br>
              </h1>
              <h3
                v-if="this.data.carr"
                style="padding-top: 40px; border-top: solid 2px black; text-align: right; right: 0%!important;color: black;position: fixed;top:107%; width: 100% "
              >
                {{ this.message }} : رقم  المركبة
              </h3>
              <h3
                style=" text-align: right; right: 0%!important;color: black;position: fixed;top:115%; width: 100% "
              >
                : مواصفات المركبة
              </h3>
              <h3
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:119%; width: 33%"
              >
                &nbsp;:   الصانع
              </h3> <h3
                v-if="this.data.carr"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:119%; width: 33%"
              >
                {{ SelectedCar(data.carr)['manufacturer'] }}
              </h3>
              <h3
                style="text-align: right; right: 33%!important;color: black;position: fixed;top:119%; width: 33%"
              >

                &nbsp; :   الطراز
              </h3> <h3
                v-if="this.data.carr"
                style="text-align: center; right: 33%!important;color: black;position: fixed;top:119%; width: 33%"
              >
                {{ SelectedCar(data.carr)['model'] }}
              </h3>
              <h3
                style="text-align: right; right: 66%!important;color: black;position: fixed;top:119%; width: 33%"
              >

                &nbsp;  : نوع المركبة

              </h3> <h3
                v-if="this.data.carr && this.data.owner_type "
                style="text-align: center; right: 70%!important;color: black;position: fixed;top:119%; width: 33%"
              >
                {{ $t(SelectedCar(data.carr)['car_type']) }} / {{ this.data.owner_type ? $t('specific') : $t('public') }}
              </h3>
              <h3
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:123%; width: 25%"
              >
                &nbsp;:   سنة الصنع
              </h3> <h3
                v-if="this.data.created_year"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:123%; width: 25%"
              >
                {{ this.data.created_year }}
              </h3>
              <h3
                style="text-align: right; right: 25%!important;color: black;position: fixed;top:123%; width: 25%"
              >

                &nbsp; :   سنة التسجيل الأولى
              </h3> <h3
                v-if="this.data.first_registeraion"
                style="text-align: center; right: 35%!important;color: black;position: fixed;top:123%; width: 25%"
              >
                {{ this.data.first_registeraion }}
              </h3>
              <h3
                style="text-align: right; right: 55%!important;color: black;position: fixed;top:123%; width: 25%"
              >

                &nbsp; :   عدد المقاعد
              </h3> <h3
                v-if="this.data.carr"
                style="text-align: center; right: 55%!important;color: black;position: fixed;top:123%; width: 25%"
              >
                {{ SelectedCar(data.carr)['passenger'] }}
              </h3>
              <h3
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:123%; width: 25%"
              >

                &nbsp; :   الحمولة
              </h3> <h3
                v-if="this.data.carr"
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:123%; width: 25%"
              >
                {{ SelectedCar(data.carr)['wight1'] }}
              </h3>
              <h3
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:127%; width: 75%"
              >
                &nbsp; :   رقم الهيكل
              </h3> <h3
                v-if="this.data.chassis_number"
                style="text-align: center; right: 0%!important;color: black;position: fixed;top:127%; width: 75%"
              >
                {{ this.data.chassis_number }}
              </h3>
              <h3
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:127%; width: 25%"
              >
                &nbsp; :   اللون
              </h3> <h3
                v-if="this.data.color"
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:127%; width: 25%"
              >
                {{ this.data.color }}
              </h3>
              <h2
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:131%; width: 25%"
              >
                &nbsp; :   مفصل الهوية
              </h2>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:134%; width: 45%"
              >
                &nbsp; :   الاسم الكامل
              </h3>
              <h3
                v-if="this.data.owwner"
                style="text-align: center; right: 10%!important;color: black;position: fixed;top:134%; width: 45%"
              >
                {{ SelectedOwner(data.owwner)['owner'] }}
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:137%; width: 45%"
              >
                &nbsp; :   رقم الاثبات الشخصي
              </h3>
              <h3
                v-if="this.data.owwner"
                style="text-align: center; right: 10%!important;color: black;position: fixed;top:137%; width: 45%"
              >
                {{ SelectedOwner(data.owwner)['identify'] }}
              </h3>
              <h3
                style="text-align: right; right: 55%!important;color: black;position: fixed;top:137%; width: 45%"
              >
                &nbsp; :   نوع الاثبات الشخصي
              </h3>
              <h3
                v-if="this.data.owwner"
                style="text-align: center; right: 60%!important;color: black;position: fixed;top:137%; width: 45%"
              >
                {{ $t(SelectedOwner(data.owwner)['identification_type']) }}
              </h3>
              <h3
                style="text-align: right; right: 55%!important;color: black;position: fixed;top:134%; width: 45%"
              >
                &nbsp; :   محل ورقم القيد
              </h3>
              <h3
                v-if="this.data.owwner"
                style="text-align: center; right: 55%!important;color: black;position: fixed;top:134%; width: 45%"
              >
                {{ $t(SelectedOwner(data.owwner)['placeNo']) }}
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:141%; width: 90%"
              >
                &nbsp; :   العنوان
              </h3>
              <h3
                v-if="this.data.owwner"
                style="text-align: center; right: 10%!important;color: black;position: fixed;top:141%; width: 90%"
              >
                {{ $t(SelectedOwner(data.owwner)['adress']) }}
              </h3>
              <h2
                style="text-align: right; right: 0%!important;color: black;position: fixed;top:147%; width: 90%"
              >
                إقرار خطي -
              </h2>
              <h2
                style="text-align: right; right: 15%!important;color: black;position: fixed;top:147%; width: 90%"
              >
                &lt; تصريح &gt;
              </h2>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:150%; width: 60%"
              >
                : اقر وأعترف وأنا بكامل الأهلية المعتبرة شرعا" وقانونا" بأن المركبة
              </h3>
              <h3
                v-if="this.data.carr"
                style="text-align: center; right: 70%!important;color: black;position: fixed;top:150%; width: 30%"
              >
                {{ SelectedCar(data.carr)['model'] }} - {{ SelectedCar(data.carr)['manufacturer'] }}
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:153%; width: 30%"
              >
                &nbsp; :   نوع
              </h3>
              <h3
                v-if="this.data.carr && this.data.owner_type "
                style="text-align: center; right: 15%!important;color: black;position: fixed;top:153%; width: 30%"
              >
                {{ $t(SelectedCar(data.carr)['car_type']) }} / {{ this.data.owner_type ? $t('specific') : $t('public') }}
              </h3>
              <h3
                style="text-align: right; right: 45%!important;color: black;position: fixed;top:153%; width: 50%"
              >
                &nbsp; :   والتي ضمن تسجيلها لديكم برقم
              </h3>
              <h3
                v-if="this.data.carr "
                style="text-align: center; right: 75%!important;color: black;position: fixed;top:153%; width: 25%"
              >
                {{ this.message }}
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:156%; width: 85%"
              >
                &nbsp;    هي بملكيتي وعائدة لي وإنني حائز عليها بشكل شرعي وقانوني بناء على هذا التصريح فإنني أتحمل المسؤولية الشرعية والقانونية في حال تبين عدم صحة إقراري وعدم صحة الوثائق المبرزة من قبلي عند تسجيل هذه المركبة وإنني ضامن لهذا الأمر من الناحية المادية والجزائية
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:162%; width: 50%"
              >
                &nbsp; :  تم استيفاء رسوم التسجيل بموجب الإيصال المالي رقم
              </h3>
              <h3
                v-if=" this.data.carr "
                style="text-align: center; right: 50%!important;color: black;position: fixed;top:162%; width: 25%"
              >
                {{ this.message }}
              </h3>
              <h3 style="text-align: right; right: 75%!important; color: black;position: fixed;top:162%; ">
                {{ currentDateTime() }} :&nbsp; تاريخ
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:165%; width: 50%"
              >
                القيود والوثائق المتتمة
              </h3>
              <h3
                style="text-align: right; right: 85%!important;color: black;position: fixed;top:171%; width: 50%"
              >
                توقيع المصرح
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:177%; width: 50%"
              >
                مكتب التسجيل
              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:180%; width: 25%"
              >
                &nbsp; :  محضر عقد رقم
              </h3>
              <h3
                style="text-align: right; right: 35%!important;color: black;position: fixed;top:180%; width: 25%"
              >
                --------/----/----                تاريخ

              </h3>
              <h3
                style="text-align: right; right: 60%!important;color: black;position: fixed;top:180%; width: 100%"
              >
                أنا الموقع رئيس تسجيل عقود السيارات
              </h3><h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:183%; width: 25%"
              >
                &nbsp; :  أنه في الساعة
              </h3>
              <h3
                style="text-align: right; right: 35%!important;color: black;position: fixed;top:183%; width: 50%"
              >
                فقد تم تسجيل مضمون هذا العقد في السجل

              </h3>
              <h3
                style="text-align: right; right: 10%!important;color: black;position: fixed;top:187%; width: 50%"
              >
                منظم العقد

              </h3>
              <h3
                style="text-align: right; right: 75%!important;color: black;position: fixed;top:187%; width: 25%"
              >
                مدير النقل في {{ user.company_data.name }}

              </h3>
            </div>

          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'عادي' },
        { value: 'Assa', text: 'قصة' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        created_year: '',
        car: null,
        platee: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        first_fee: 0,
        yearly_fee: 0,
        other_fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/GetAuth']
    },
    Total() {
      let totalfee = 0
      totalfee = parseFloat(this.data.fee) + (parseFloat(this.data.other_fee) * parseFloat(this.Tolls.dollar))
      return totalfee
    },
    Platees() {
      return this.$store.getters['platee/GetElements']
    },
    Checkk() {
      return this.$store.getters['checkk/GetAvailableElements']
    },
    Owwners() { return this.$store.getters['owwner/GetElements'] },
    Tolls() { return this.$store.getters['toll/GetElements'] },

    Carrs() { return this.$store.getters['carr/GetElements'] },
  },
  watch: {
    'data.color': function () {
      this.data.color1 = this.data.color
      this.data.color2 = this.data.color.substring(this.data.color1.split(' ')[0].length)
    },
    // eslint-disable-next-line func-names
    'data.chassis_number': function (newEl) {
      const year = this.created_year[newEl.slice(10, 11)]
      if (year) {
        this.data.created_year = year
      }
    },
    'data.owwner': function (val) {
      // this.resetData()
      if (val) {
        const SelectedOwwners = this.Owwners.find(el => el.id === val)
        const SelectedOwners = SelectedOwwners.checkks[SelectedOwwners.owwners_count - 1]
        if (SelectedOwwners.owwners_count > 0) {
          this.datanew = false
          this.data.brake = SelectedOwners.brake
          this.data.platee = SelectedOwners.platee_id
          this.data.cabin_number = SelectedOwners.cabin_number
          this.data.cabin_number_type = SelectedOwners.cabin_number_type
          this.data.cabin_reserve_number = SelectedOwners.cabin_reserve_number
          this.data.cabin_reserve_number_type = SelectedOwners.cabin_reserve_number_type
          this.data.car_type = SelectedOwners.car_type
          this.data.carr = SelectedOwners.carr_id
          const car = this.SelectedCar(SelectedOwners.carr_id)
          if (this.data.fee < 1 && this.data.created_year > 1900) {
            if (this.data.created_year < 2006) {
              if (car.car_type === 'tourism' && car.motor_power <= 1600) {
                this.data.fee = (parseFloat(this.Tolls.tourismOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourismOneFirst)
              } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
                this.data.fee = (parseFloat(this.Tolls.tourismTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourismTwoFirst)
              } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
                this.data.fee = (parseFloat(this.Tolls.tourismThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourismThreeFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
                this.data.fee = (parseFloat(this.Tolls.transportOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transportOneFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
                this.data.fee = (parseFloat(this.Tolls.transportTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transportTwoFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
                this.data.fee = (parseFloat(this.Tolls.transportThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transportThreeFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
                this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
                this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
                this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
                this.data.fee = (parseFloat(this.Tolls.busOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.busOneFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
                this.data.fee = (parseFloat(this.Tolls.busTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.busTwoFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
                this.data.fee = (parseFloat(this.Tolls.busThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.busThreeFirst)
              } else if (car.car_type === 'goverment') {
                this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
              } else if (car.car_type === 'motor') {
                this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
              }
            } else if (this.data.created_year > 2005) {
              if (car.car_type === 'tourism' && car.motor_power <= 1600) {
                this.data.fee = (parseFloat(this.Tolls.tourism2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourism2OneFirst)
              } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
                this.data.fee = (parseFloat(this.Tolls.tourism2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourism2TwoFirst)
              } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
                this.data.fee = (parseFloat(this.Tolls.tourism2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.tourism2ThreeFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
                this.data.fee = (parseFloat(this.Tolls.transport2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transport2OneFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
                this.data.fee = (parseFloat(this.Tolls.transport2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transport2TwoFirst)
              } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
                this.data.fee = (parseFloat(this.Tolls.transport2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.transport2ThreeFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
                this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
                this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
              } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
                this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
                this.data.fee = (parseFloat(this.Tolls.bus2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.bus2OneFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
                this.data.fee = (parseFloat(this.Tolls.bus2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.bus2TwoFirst)
              } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
                this.data.fee = (parseFloat(this.Tolls.bus2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.bus2ThreeFirst)
              } else if (car.car_type === 'goverment') {
                this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
              } else if (car.car_type === 'motor') {
                this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
                this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
              }
            }
          }
          this.data.fee = parseFloat(this.data.fee)
          this.data.chassis_number = SelectedOwners.chassis_number
          this.data.created_year = SelectedOwners.created_year
          this.data.chassis_number_type = SelectedOwners.chassis_number_type
          this.data.color = SelectedOwners.color
          this.data.combustion = SelectedOwners.combustion
          this.data.expiration_date = SelectedOwners.expiration_date
          this.data.first_check = SelectedOwners.first_check
          this.data.first_registeraion = SelectedOwners.first_registeraion
          this.data.fuel_type = SelectedOwners.fuel_type
          this.data.light = SelectedOwners.light
          this.data.motor_manufacturer = SelectedOwners.motor_manufacturer
          this.data.motor_model = SelectedOwners.motor_model
          this.data.motor_number = SelectedOwners.motor_number
          this.data.motor_number_type = SelectedOwners.motor_number_type
          this.data.motor_reserve_number = SelectedOwners.motor_reserve_number
          this.data.motor_reserve_number_type = SelectedOwners.motor_reserve_number_type
          this.data.owner_type = SelectedOwners.owner_type
          this.data.owwner_id = SelectedOwners.owwner_id
          this.data.first_check = false
          this.data.wheel = SelectedOwners.wheel
          this.datanew = false
        } else {
          this.data.brake = true
          this.data.cabin_number = null
          this.data.cabin_number_type = null
          this.data.cabin_reserve_number = null
          this.data.cabin_reserve_number_type = null
          this.data.car_type = null
          this.data.chassis_number = null
          this.data.created_year = null
          this.data.chassis_number_type = null
          this.data.color = null
          this.data.combustion = true
          this.data.wheel = true
          this.data.first_registeraion = null
          this.data.fuel_type = null
          this.data.light = true
          this.data.motor_manufacturer = null
          this.data.motor_model = null
          this.data.motor_number = null
          this.data.motor_number_type = null
          this.data.motor_reserve_number = null
          this.data.motor_reserve_number_type = null
          this.data.owner_type = true
          this.data.owwner_id = null
          this.datanew = true
        }
      } else {
        this.clearData()
      }
    },
    'data.other_fee': function (val) {
      if (!val) {
        this.data.other_fee = 0
      }
    },
    // eslint-disable-next-line func-names
    'data.carr': function (cartype) {
      // this.data.platee = 5
      const car = this.SelectedCar(cartype)
      this.data.fee = 0
      this.getPlatee(car.car_type)
      // this.data.platee = this.Platees[0]
      if (this.data.fee < 1 && this.data.created_year > 1900) {
        if (this.data.created_year < 2006) {
          if (car.car_type === 'tourism' && car.motor_power <= 1600) {
            this.data.fee = (parseFloat(this.Tolls.tourismOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismOneFirst)
          } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourismTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismTwoFirst)
          } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourismThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismThreeFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.transportOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportOneFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.transportTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportTwoFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.transportThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportThreeFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
            this.data.fee = (parseFloat(this.Tolls.busOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busOneFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
            this.data.fee = (parseFloat(this.Tolls.busTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busTwoFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
            this.data.fee = (parseFloat(this.Tolls.busThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busThreeFirst)
          } else if (car.car_type === 'goverment') {
            this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
          } else if (car.car_type === 'motor') {
            this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
          }
        } else if (this.data.created_year > 2005) {
          if (car.car_type === 'tourism' && car.motor_power <= 1600) {
            this.data.fee = (parseFloat(this.Tolls.tourism2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2OneFirst)
          } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourism2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2TwoFirst)
          } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourism2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2ThreeFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.transport2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2OneFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.transport2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2TwoFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.transport2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2ThreeFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
            this.data.fee = (parseFloat(this.Tolls.bus2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2OneFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
            this.data.fee = (parseFloat(this.Tolls.bus2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2TwoFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
            this.data.fee = (parseFloat(this.Tolls.bus2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2ThreeFirst)
          } else if (car.car_type === 'goverment') {
            this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
          } else if (car.car_type === 'motor') {
            this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
          }
        }
      }
      this.data.fee = parseFloat(this.data.fee)
      this.data.first_fee = this.Tolls.first
    },
    'data.created_year': function () {
      const car = this.SelectedCar(this.data.carr)
      this.data.fee = 0
      if (this.data.fee < 1 && this.data.created_year > 1900) {
        if (this.data.created_year < 2006) {
          if (car.car_type === 'tourism' && car.motor_power <= 1600) {
            this.data.fee = (parseFloat(this.Tolls.tourismOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismOneFirst)
          } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourismTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismTwoFirst)
          } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourismThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourismThreeFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.transportOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportOneFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.transportTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportTwoFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.transportThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transportThreeFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
            this.data.fee = (parseFloat(this.Tolls.busOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busOneFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
            this.data.fee = (parseFloat(this.Tolls.busTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busTwoFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
            this.data.fee = (parseFloat(this.Tolls.busThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.busThreeFirst)
          } else if (car.car_type === 'goverment') {
            this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
          } else if (car.car_type === 'motor') {
            this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
          }
        } else if (this.data.created_year > 2005) {
          if (car.car_type === 'tourism' && car.motor_power <= 1600) {
            this.data.fee = (parseFloat(this.Tolls.tourism2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2OneFirst)
          } else if (car.car_type === 'tourism' && car.motor_power <= 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourism2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2TwoFirst)
          } else if (car.car_type === 'tourism' && car.motor_power > 2000) {
            this.data.fee = (parseFloat(this.Tolls.tourism2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.tourism2ThreeFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.transport2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2OneFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.transport2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2TwoFirst)
          } else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'transport') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.transport2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.transport2ThreeFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight <= 3000) {
            this.data.fee = (parseFloat(this.Tolls.spicialOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialOneFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight < 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialTwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialTwoFirst)
          } else if ((car.car_type === 'agricultural' || car.car_type === 'work' || car.car_type === 'spicial') && car.wight >= 11000) {
            this.data.fee = (parseFloat(this.Tolls.spicialThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.spicialThreeFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 14) {
            this.data.fee = (parseFloat(this.Tolls.bus2OneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2OneFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger <= 30) {
            this.data.fee = (parseFloat(this.Tolls.bus2TwoFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2TwoFirst)
          } else if ((car.car_type === 'transport_ride' || car.car_type === 'microbus' || car.car_type === 'bus') && car.passenger > 30) {
            this.data.fee = (parseFloat(this.Tolls.bus2ThreeFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.bus2ThreeFirst)
          } else if (car.car_type === 'goverment') {
            this.data.fee = (parseFloat(this.Tolls.govermentOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.govermentOneFirst)
          } else if (car.car_type === 'motor') {
            this.data.fee = (parseFloat(this.Tolls.motorOneFirst) + parseFloat(this.Tolls.first)) * parseFloat(this.Tolls.dollar)
            this.data.yearly_fee = parseFloat(this.Tolls.motorOneFirst)
          }
        }
      }
      this.data.fee = parseFloat(this.data.fee)
      this.data.first_fee = this.Tolls.first
    },
  },
  created() {
    this.data.expiration_date = this.moment().add(12, 'months').format('YYYY-MM-DD')
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('carr/GetElements')
    this.$store.dispatch('toll/GetPluckedElements')
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const dateTime = `${date}`

      return dateTime
    },
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      // await this.$htmlToPaper('printMe', { options })
      await this.$htmlToPaper('printMe', { options, styles: ['/style.css'] })
      setTimeout(() => { this.clearData() }, 1000)
      this.resetData()
    },
    clearData() {
      this.data = {
        fee: 0,
        other_fee: 0,
        yearly_fee: 0,
        first_fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        platee: null,
        light: true,
        combustion: true,
        wheel: true,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
    },
    getPlatee(val) {
      this.$store.dispatch('platee/GetAvailableElements', val).then(() => {
        this.data.platee = this.Platees[0].id
      })
    },
    getData(val) {
      this.$store.dispatch('checkk/GetAvailableElements', val).then(() => {
        this.data = this.Element
      })
    },
    SelectedCar(val) {
      return this.Carrs.find(el => el.id === val)
    },
    SelectedOwner(val) {
      return this.Owwners.find(el => el.id === val)
    },
    resetData() {
      this.$store.dispatch('owwner/GetAvailableElements')
      this.data = {
        fee: 0,
        other_fee: 0,
        yearly_fee: 0,
        first_fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
        platee: null,
        car: null,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    ExpirationDateAfter(months) {
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.data.fee = parseInt(this.Total, 10)
      this.errors = []
      if ((this.data.platee === null || this.data.platee === '') && this.datanew) {
        alert('الرجاء اختيار اللوحة ')
      } else {
        this.$store.dispatch('checkk/Create', this.data).then(response => {
          this.message = response.data.message.slice(45)
          if (this.message) {
            setTimeout(() => { this.print(this.message) }, 5000)
          }
          this.Platees.splice(this.Platees[0], 1)
        }).catch(error => {
          this.errors = error.response.data.errors
        })
      }
    },
  },
}
</script>
